import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { Button, Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from "react-router-dom";
import Section from '../../component/admin/Section';
import Api from '../../api/Api';
import { MdOutlineRemoveRedEye, MdEdit } from 'react-icons/md';
import CustomPagination from '../../component/admin/CustomPagination';
import Image from 'react-bootstrap/Image';
const User = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0);
 
 

  const allUsers = () =>{
    Api.getAllUsers(page, limit).then((resData)=>{
     
      setData(resData.result)
      setCount(resData.docCount)
    })
  }

  const handleView = (id) =>{
    navigate(`/admin/user/${id}`)
  }
  const handleTicketEdit = (id) =>{
    navigate(`/admin/user/wallet/${id}`)
  }

  

  useEffect(()=>{
    allUsers();
  },[page])

  return (
    <Col className='content' lg={10} >
      
          <Section title='Users' >
          <table class="table">
                <thead>
                <tr className='th'>
                    <th className='head' scope="col">#</th>
                    <th className='head' scope="col">Display Name</th>
                    <th className='head' scope="col">Username</th>
                    <th className='head' scope="col">profile_image</th>
                    <th className='head' scope="col">Created At</th>
                    <th className='head' scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                    {
                        data && data.map( (item, index) => 
                        <tr key={index}>
                          
                            <th scope="row">{(index+1)+(page * limit) - limit}</th>
                            <td>{item.displayName} </td>
                            <td>{item.username}</td>
                            <td><Image src={item.profile_image} roundedCircle /></td>
                            <td>{ moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') }</td>
                            <td>
                            <Button  onClick={()=>handleView(item._id)} variant='info' className='btn-darkblue me-2'><MdOutlineRemoveRedEye /></Button>
                            <Button  onClick={()=>handleTicketEdit(item._id)} variant='warning' className='btn-darkblue me-2'><MdEdit /></Button></td>
                        </tr>
                        )
                    }
            
                </tbody>
            </table>
            <CustomPagination page={page} counts={count} limit={limit} setPage={(value)=> setPage(value)} />
          </Section>
          <br />
          

   
    </Col>
  )
}

export default User