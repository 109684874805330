import React, { useState } from 'react'
// import LogoHorizontal from '../logo-trim.png'
// import Button from 'react-bootstrap/Button';
// import { Col, Container, Row } from 'react-bootstrap';
import Logo from '../assets/public/LOGO.png';
import Img_ConnectBottom from '../assets/public/connectBottom.png'
import { useLocation } from 'react-router-dom';

const Login = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Extract the referral code from the query parameters
  const referralCode = queryParams.get('referralCode');


  const loginHandle = (referralCode) => {
    
    if(queryParams.get('referralCode')){
    window.location.replace(`${process.env.REACT_APP_DEFAULT_LOCAL_API_URL}/auth/twitter?referralCode=${referralCode}`);
    }else{
      window.location.replace(`${process.env.REACT_APP_DEFAULT_LOCAL_API_URL}/auth/twitter`);
    }
  }
    
   

  return (
    <>
      <div className='bg-[#6141A6]' style={{"font-family":'Josefin Sans, sans-serif', }}>
      <div class="flex justify-center flex-col gap-20  items-center max-w-xxl h-screen  mx-auto lg:px-8 ">

        <div class="">
          <img src={Logo} alt="Astroon" />
        </div>

        <div class="bg-white  px-6 py-12 pb-24 h-auto rounded-[32px] mx-auto sm:mx-auto sm:w-[100%] max-sm:max-w-[100%] md:max-w-xl md:w-full relative" style={{width:'100%'}}>
          <div>
            <h1 class="font-josefin-sans text-[40px] font-extrabold leading-none text-center text-[#6141A6]">Sign In</h1>
          </div>

            {/* <div class="flex justify-center mt-16">
              <form action="?" method="POST">
                <div class="g-recaptcha" data-sitekey="6Lc2YfUpAAAAAMvaAGDIE8ggEwidFtpxhWpS1iwZ"></div>
                <br/>
              </form>
            </div> */}

          <div class="flex justify-center mt-[45px] mb-[30px]">
            <button 
              type="submit"
              class="flex justify-center rounded-[16px] bg-[#6141A6] px-[24px] py-[20px] text-[24px]   leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#6141A6] items-center"
              onClick={()=>loginHandle(referralCode)}>
                Connect With X
            </button>
          </div>

          <div class="absolute md:-bottom-20 -bottom-12">
            <img src={Img_ConnectBottom} />
          </div>
        </div>
        </div>
         
      </div>
    </>
  )
}

export default Login