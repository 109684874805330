import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeAdmin: 'task',
}
export const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        setAdminMenu: (state, action) =>{
            state.activeAdmin = action.payload;
        },
    }
})

export const { setAdminMenu } = menuSlice.actions;

export default menuSlice.reducer;