import React from 'react'

// Images
import Img_frame1 from '../../assets/public/frame1.png';
import { useSelector } from 'react-redux';

const Giveaways = () => {
  const authData = useSelector( (state) =>  state.auth );

  return (
    <>
    <div data-id="3" className="contents-data transition-all">
      <div className="py-5 px-5 space-y-10">
        <div className="relative flex justify-center items-center">
          <img src={Img_frame1} alt="" srcset="" />
          <div className="absolute text-white text-2xl md:text-4xl lg:text-7xl flex justify-between items-center w-full px-5">
            Tickets<span>{ authData?.wallet?.tickets}</span> 
          </div>
        </div>
        <div >
          <div className="grid grid-cols-3 gap-3 md:gap-y-0 md:grid-cols-5 md:gap-x-5">
              <div className="space-y-3">
                <div className="flex justify-center items-center">
                  <button 
          className="tab-btn w-full  focus:outline-none p-2 text-nowrap md:p-3 text-white font-bold border border-white rounded-md active-tab">Reward</button>
                </div>
                <div className="border md:text-xl border-white space-y-4 p-3 text-center rounded-lg">
                    <h4 className="text-white space-x-1 md:space-x-2"><span>150</span><span>AST</span></h4>
                    <h4 className="text-gray-50/80 space-x-1 md:space-x-2"><span>150</span><span>AST</span></h4>
                    <h4 className="text-white space-x-1 md:space-x-2"><span>150</span><span>AST</span></h4>
                    <h4 className="text-gray-50/80 space-x-1 md:space-x-2"><span>150</span><span>AST</span></h4>
                    <h4 className="text-white space-x-1 md:space-x-2"><span>150</span><span>AST</span></h4>
                    <h4 className="text-gray-50/80 space-x-1 md:space-x-2"><span>150</span><span>AST</span></h4>
                    <h4 className="text-white space-x-1 md:space-x-2"><span>150</span><span>AST</span></h4>
                    <h4 className="text-gray-50/80 space-x-1 md:space-x-2"><span>150</span><span>AST</span></h4>
                    <h4 className="text-white space-x-1 md:space-x-2"><span>150</span><span>AST</span></h4>
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex justify-center items-center">
                  <button 
          className="tab-btn w-full focus:outline-none p-2 text-nowrap md:p-3 text-white font-bold border border-white rounded-md active-tab">Total Tickets</button>
                </div>
                <div className="border md:text-xl  border-white space-y-4 p-3 text-center rounded-lg">
                    <h4 className="text-white space-x-2"><span>1000</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>1000</span></h4>
                    <h4 className="text-white space-x-2"><span>1000</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>1000</span></h4>
                    <h4 className="text-white space-x-2"><span>1000</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>1000</span></h4>
                    <h4 className="text-white space-x-2"><span>1000</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>1000</span></h4>
                    <h4 className="text-white space-x-2"><span>1000</span></h4>
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex justify-center items-center">
                  <button 
          className="tab-btn w-full focus:outline-none p-2 text-nowrap md:p-3 text-white font-bold border border-white rounded-md active-tab">Ticket Spent</button>
                </div>
                <div className="border md:text-xl border-white space-y-4 p-3 text-center rounded-lg">
                    <h4 className="text-white space-x-2"><span>1000</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>1000</span></h4>
                    <h4 className="text-white space-x-2"><span>1000</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>1000</span></h4>
                    <h4 className="text-white space-x-2"><span>1000</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>1000</span></h4>
                    <h4 className="text-white space-x-2"><span>1000</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>1000</span></h4>
                    <h4 className="text-white space-x-2"><span>1000</span></h4>
                </div>
              </div>
              <div className="space-y-3 col-span-2 md:col-span-1">
                <div className="flex justify-center items-center">
                  <button
          className="tab-btn  focus:outline-none p-2 md:w-full  md:p-3 text-white font-bold border border-white rounded-md active-tab">Remaining Time</button>
                
                </div>
                <div className="border md:text-xl border-white space-y-4 p-3 text-center rounded-lg">
                    <h4 className="text-white space-x-2"><span>10H</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>10H</span></h4>
                    <h4 className="text-white space-x-2"><span>10H</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>10H</span></h4>
                    <h4 className="text-white space-x-2"><span>10H</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>10H</span></h4>
                    <h4 className="text-white space-x-2"><span>10H</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>10H</span></h4>
                    <h4 className="text-white space-x-2"><span>10H</span></h4>
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex justify-center items-center">
                  <button 
          className="tab-btn w-full focus:outline-none p-2 text-nowrap md:p-3 text-white font-bold border border-white rounded-md active-tab">Winner</button>
                
                </div>
                <div className="border md:text-xl border-white space-y-4 p-3 text-center rounded-lg">
                    <h4 className="text-white space-x-2"><span>xyz</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>xyz</span></h4>
                    <h4 className="text-white space-x-2"><span>xyz</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>xyz</span></h4>
                    <h4 className="text-white space-x-2"><span>xyz</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>xyz</span></h4>
                    <h4 className="text-white space-x-2"><span>xyz</span></h4>
                    <h4 className="text-gray-50/80 space-x-2"><span>xyz</span></h4>
                    <h4 className="text-white space-x-2"><span>xyz</span></h4>
                </div>
              </div>
          </div>
        </div>
    </div>
    </div>
    </>
  )
}

export default Giveaways