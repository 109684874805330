import Switch from "react-switch";
import { useState } from "react";
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import Api from "../../api/Api";
const ChangeStatus = (props) =>{
    const { currentStatus, id } = props;
    const [status, setStatus] = useState(currentStatus);
    const onChnageHandler = async ()=>{
        confirmAlert({
            title: 'Confirm to Change',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: async () => {
                    toast.promise(Api.changeTaskStatus(id, {status : status ? 0 : 1}).then((result)=>{
                        console.log('Task Create -- ',result) 
                        return result;
                      }),
                      {
                        pending: 'Chaning...',
                        success: 'Status Change Successfully. 👌',
                        error: 'Task rejected 🤯'
                      })
                    setStatus(status ? 0 : 1)
                }
              },
              {
                label: 'No',
                onClick: () => {
                    toast.warning("Nothing to Change");
                }
              }
            ]
          });
    }
    return(<Switch onChange={onChnageHandler} checked={status} />);
}
export default ChangeStatus;