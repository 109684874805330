import React, { useEffect, useState } from 'react'

// Images
import Img_frame1 from '../../assets/public/frame1.png';
import { useSelector } from 'react-redux';
import Api from '../../api/Api';
import Record from './leaderboard/Record';
import { isEmpty } from '../../helper/Helper';

const Leaderboard = () => {
  const authData = useSelector( (state) =>  state.auth );
  const leaderboardData = useSelector( (state) =>  state.leaderboard );
  const [activeTab, setActiveTab] = useState('weekly');

  // Static Data
  const tabs = [{name: 'Weekly', id: 'weekly'}, {name: 'Monthly', id: 'monthly'}, { name: 'All Time', id: 'alltime'}]
  const activeTabClass = "tab-btn focus:outline-none p-3 md:px-10 text-white font-bold border border-white rounded-2xl md:text-xl  active-tab";
  const tabClass = "tab-btn focus:outline-none p-3 md:px-10 text-white font-bold border border-white rounded-2xl md:text-xl";


  useEffect(()=>{
    Api.getLeaderboards();
  },[])


  return (
    <>
    <div className="contents-data transition-all">
      <div className="py-5 px-5 space-y-10">
        <div className="relative flex justify-center items-center">
          <img src={Img_frame1} alt="" srcset="" />
          <div className="absolute text-white text-2xl md:text-4xl lg:text-7xl">
            Total Community Points {authData?.wallet?.cp}
          </div>
        </div>
        <div id="tabs" className="flex justify-center items-center space-x-10">
                {tabs.map( item => 
                    <button 
                        key={item.id}
                        onClick={()=> setActiveTab(item.id)} 
                        className={item.id == activeTab ? activeTabClass : tabClass} 
                    >
                    {item.name}
                    </button>
                )}
        </div>

        <div className="border border-white p-5 gap-5 rounded-lg space-y-5">
          <div className="text-white text-xl md:text-5xl font-bold">
            <h4>Leaderboard</h4>
          </div>
          <div className="flex flex-col justify-center items-center">
            {
              leaderboardData?.allTime.length && 
              activeTab === 'alltime'? leaderboardData?.allTime?.map( (item, index) => 
                <Record item={item} index={index} key={item.username} />
              ) :
              activeTab === 'weekly'? leaderboardData?.weekly?.map( (item, index) => 
                <Record item={item} index={index} key={item.username} />
              ) :
              activeTab === 'monthly'? leaderboardData?.monthly?.map( (item, index) => 
                <Record item={item} index={index} key={item.username} />
              ) : '' 
            }
            
            
          </div>
        </div>
      </div>
      
    </div>
    </>
  )
}

export default Leaderboard