import React, { useEffect, useState } from 'react'
import Api from '../api/Api'
import TaskTypeFilter from './TaskTypeFilter'
import TaskButton from './TaskButton'
import { useSelector } from 'react-redux';
const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

const TaskList = () => {
    const [data, setData] = useState([])
    const [onTimeTask, setOnTimeTask] = useState([])
    const [dailyCompleteTask, setDailyCompleteTask] = useState([])
    const [dailyPendingTask, setDailyPendingTask] = useState([])
    const [referralCp, setReferralCp] = useState(0);
    const [referralTickets, setReferralTickets] = useState(0);
    const authData = useSelector( (state) => state.auth );

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`${LOGIN_URL}?referralCode=${authData?.user._id}`).then(
          () => {
            alert('Link copied to clipboard!');
          },
          (err) => {
            alert('Failed to copy the link.');
          }
        );
      };

    useEffect(()=>{
        Api.getPendingTask().then((resData)=>{
            setOnTimeTask(resData?.oneTimeTask);
            setDailyCompleteTask(resData?.dailyCompleteTask);
            setDailyPendingTask(resData?.dailyPendingTask);
            console.log(resData)
            setData(resData);
        })
        Api.getSettings().then((resData)=>{
            setReferralCp(resData.referralCp)
            setReferralTickets(resData.referralTickets)
        })
    },[])

  return (
    <div className="bg-[#3B3938] p-8 rounded-lg">
        <div className="flex justify-between items-center">
            <div className="text-white text-left space-y-2">
                <h4 className="text-2xl md:text-5xl">Tasks</h4>
                <h4 className="text-ellipsis text-sm md:text-base">Complete tasks to earn more community points.</h4>
            </div>
            {/* <div>
                <button className="p-3 border border-white text-white font-bold px-5 rounded-lg">Calendar</button>
            </div> */}
        </div>
        <div className="py-5 space-y-8">
            <div className="border border-white flex justify-between items-center rounded-lg">
                <div className="flex justify-center items-center gap-x-3 md:gap-5">
                    <div className="bg-white text-web text-center p-3 md:p-5 rounded-lg text-nowrap">
                        <h4>+{referralCp} CP</h4>
                        <h4>{referralTickets} Tickets</h4>
                    </div>
                    <TaskTypeFilter type={'REFERE'} />
                </div>
                <div className="px-5">
                    <button className="bg-web text-white font-bold rounded-lg p-3 px-5" onClick={()=>copyToClipboard()} >GO</button>
                </div>
            </div>
            { dailyCompleteTask.length > 0 ? 
                dailyCompleteTask.map( (item, index) => 
                    <div className="border border-white flex justify-between items-center rounded-lg">
                        <div className="flex justify-center items-center gap-x-3 md:gap-5">
                            <div className="bg-white text-web text-center p-3 md:p-5 rounded-lg text-nowrap">
                                <h4>+{item.cp} CP</h4>
                                <h4>{item.tickets} Tickets</h4>
                            </div>
                            <TaskTypeFilter type={item.type} />
                        </div>
                        <TaskButton task={item} status={0} />
                    </div>
                )
                : ''
            }
            { dailyPendingTask.length > 0 ? 
                dailyPendingTask.map( (item, index) => 
                    <div className="border border-white flex justify-between items-center rounded-lg">
                        <div className="flex justify-center items-center gap-x-3 md:gap-5">
                            <div className="bg-white text-web text-center p-3 md:p-5 rounded-lg text-nowrap">
                                <h4>+{item.cp} CP</h4>
                                <h4>{item.tickets} Tickets</h4>
                            </div>
                            <TaskTypeFilter type={item.type} />
                        </div>
                        <TaskButton task={item} status={1} />
                    </div>
                )
                : ''
            }
            { onTimeTask.length > 0 ? 
                onTimeTask.map( (item, index) => 
                    <div className="border border-white flex justify-between items-center rounded-lg">
                        <div className="flex justify-center items-center gap-x-3 md:gap-5">
                            <div className="bg-white text-web text-center p-3 md:p-5 rounded-lg text-nowrap">
                                <h4>+{item.cp} CP</h4>
                                <h4>{item.tickets} Tickets</h4>
                            </div>
                            <TaskTypeFilter type={item.type} />
                        </div>
                        <TaskButton task={item} status={1}/>
                    </div>
                )
                : ''
            }
            
            
        </div>
    </div>
  )
}

export default TaskList