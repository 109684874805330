import React, { useEffect, useState } from 'react'
import Header from '../../layout/Header'
import moment from 'moment'

import { Button, Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from "react-router-dom";
import Section from '../../component/admin/Section';
import Api from '../../api/Api';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import CustomPagination from '../../component/admin/CustomPagination';
const UserData = () => {
   
  const navigate = useNavigate();
  const {userId} = useParams();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0);
 

  const getMonitorings = () =>{
    Api.getUserMonitorings(userId, page, limit).then((resData)=>{
     
      setData(resData.result)
      setCount(resData.docCount)
    })
  }

  const handleView = (id) =>{
    navigate(`/admin/user/${id}`)
  }

  

  useEffect(()=>{
    getMonitorings();
  },[page])

  return (
    <Col className='content' lg={10} >
      
          <Section title='Users Monıtorıng' >
          <table class="table">
                <thead>
                <tr className='th'>
                    <th className='head' scope="col">#</th>
                    <th className='head' scope="col">Type</th>
                    <th className='head' scope="col">cp</th>
                    <th className='head' scope="col">tickets</th>
                    <th className='head' scope="col">Created At</th>
                    {/* <th className='head' scope="col">Action</th> */}
                </tr>
                </thead>
                <tbody>
                    {
                        data && data.map( (item, index) => 
                        <tr key={index}>
                          
                            <th scope="row">{(index+1)+(page * limit) - limit}</th>
                            <td>{item.type} </td>
                            <td>{item.cp}</td>
                            <td>{item.tickets}</td>
                            {/* <td><Image src={item.profile_image} roundedCircle /></td> */}
                            <td>{ moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') }</td>
                            {/* <td><Button  onClick={()=>handleView(item._id)} variant='info' className='btn-darkblue me-2'><MdOutlineRemoveRedEye /></Button></td> */}
                        </tr>
                        )
                    }
            
                </tbody>
            </table>
            <CustomPagination page={page} counts={count} limit={limit} setPage={(value)=> setPage(value)} />
          </Section>
          <br />
          

   
    </Col>
  )
}

export default UserData