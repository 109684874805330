import React, {useEffect, useState} from 'react'
import Logo from '../assets/public/LOGO.png'
import { MdSpaceDashboard, MdManageAccounts } from "react-icons/md";

import Api from '../api/Api'
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../store/authSlice';
import { Link } from 'react-router-dom';

const Header = () => {

  const authData = useSelector( (state) =>  state.auth );
  const dispatch = useDispatch();
  const [todayTasks, setTodayTasks] = useState([]);

  const authUser = async() => {
    Api.login().then((resData)=>{
        // console.log(resData)
        // console.log("Failed to authenticate user")
      dispatch(setAuth(resData));
    }).catch( error => {

      console.log("Failed to authenticate user")
      dispatch(setAuth({ authenticated: false, user: Object }));
      window.location.replace('/login')
    })
  }

  const getWallet = async() =>{
    await Api.getWallet()
  }

  useEffect(()=>{
    import('./Public.css')
    authUser();
  },[])

  useEffect(()=>{
    if(!authData.authenticated) return;
    getWallet()
  },[authData.authenticated])

  return (
    <>
    <header className="flex justify-between px-3 md:px-0 md:justify-around items-center py-5">
      <nav className="flex justify-center items-center space-x-10">
        <div id="logo">
          <img src={Logo} alt="logo" />
        </div>
        <div 
          className="hidden md:flex justify-center items-center space-x-5 text-nowrap text-gray-50/80 cursor-pointer">
          <h4>Farming</h4>
          <h4>Claim Airdrop</h4>
        </div>
      </nav>
      <div id="buttons" className='flex'>
        { authData.user.username === process.env.REACT_APP_ADMIN_USERNAME? 
          <Link to={'/admin/task'} className="ms-1">
            <button class="hidden text-web sm:block px-3 py-1 bg-white font-bold rounded-xl">{ authData.authenticated &&  <>
              Admin
            </>}</button>
            {/* <button className="hidden md:block p-3 bg-white font-bold rounded-xl text-web">Sign In</button> */}
        </Link> : ''
        }
          
          <Link to={'/profile'} className="ms-1">
              <button class="hidden text-web sm:block px-3 py-1 bg-white font-bold rounded-xl">@{ authData.authenticated && <>
                {authData.user.username}
              </>}</button>
              {/* <button className="hidden md:block p-3 bg-white font-bold rounded-xl text-web">Sign In</button> */}
          </Link>
        <button onclick="openNavigation()" className="block md:hidden p-3 px-4 bg-white font-bold rounded-xl ">
          <i className="fa-solid fa-bars fa-lg"></i>
        </button>
      </div>
    </header>
    </>
  )
}

export default Header