import React from 'react'

const Record = ({item, index}) => {
  return (
    <div className="text-3xl w-full  flex justify-between items-center">
      <div className="gap-x-5">
        <span className="text-gray-50/50 ">{index+1}</span>
        <span className="text-white ms-1">{item.username}</span>
      </div>
      <span className="text-gray-50/50 ">{item.totalCp}</span>
    </div>
  )
}

export default Record