import axios from 'axios';
import { store } from '../store';
import { setWallet } from '../store/authSlice';
import { setAllTime, setMonthly, setWeekly } from '../store/leaderboardSlice';

// Axios Config 
const DEFAULT_LOCAL_API_URL = process.env.REACT_APP_DEFAULT_LOCAL_API_URL;
const DEFAULT_LOCAL_HEADER = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Credentials": true
}

const localInstance = axios.create({
    withCredentials: true,
    baseURL: DEFAULT_LOCAL_API_URL,
    headers: DEFAULT_LOCAL_HEADER
})

// API Class
class Api{
  constructor(){}

  // Login
  async login (){
      const response = await localInstance.get('/login');
      return response.data;
  }

  async adminLogin (){
    const response = await localInstance.get('/login/admin');
      return response.data;
  }
  // Logout
  async logout(){
  
    const response = await localInstance.get('/auth/logout');
    return response.data;
  
}

  // Get Today Task
  async getPendingTask(){
    try{
      const response = await localInstance.get('/task/pending');
      return response.data;
    }catch(error){
      return error;
    }
  }

  // Create task
  async createTask(params){
    try{
      const response = await localInstance.post('/task', params);
      return response.data;
    }catch(error){
      return error;
    }
  }

  async changeTaskStatus(id, params){
    try{
      const response = await localInstance.put(`/task/status/${id}`, params);
      return response.data;
    }catch(error){
      return error;
    }
  }
  // Get All Task
  async getAllTask(page, limit){
    try{
      const response = await localInstance.get(`/task?page=${page}&limit=${limit}`);
      return response.data;
    }catch(error){
      return error;
    }
  }

   // Get Wallet Info
   async getWallet(){
    try{
      const response = await localInstance.get('/wallet');
   
      store.dispatch(setWallet(response.data))
      return response.data;
    }catch(error){
      return error;
    }
  }

  // Get Leaderboard All-time
  async getLeaderboards(){

    try{
      localInstance.get('/leaderboard/all-time').then((response)=>{
        
        store.dispatch(setAllTime(response.data))
      });
      localInstance.get('/leaderboard/weekly').then((response)=>{
        store.dispatch(setWeekly(response.data))
      });
      localInstance.get('/leaderboard/monthly').then((response)=>{
        store.dispatch(setMonthly(response.data))
      });
      
    }catch(error){
      
      return error;
    }
  }
  

  // Twitter API ========================

  // test
  async testCall(tweetId){
    try{
      const response = await localInstance.post('/twitter', { tweetId: tweetId});
      return response.data;
    }catch(error){
      return error;
    }
  }

  // Create
  async createTweet(text, taskId){
    try{
      const response = await localInstance.post('/twitter/tweet', { text: text, taskId: taskId});
      return response.data;
    }catch(error){
      return error;
    }
  }

  // Like
  async likeTweet(tweetId, taskId){
    try{
      const response = await localInstance.post(`/twitter/tweet/${tweetId}/like`, {taskId: taskId});
      return response.data;
    }catch(error){
      return error;
    }
  }

  // Reply
  async replyTweet(text, tweetId, taskId){
    try{
      const response = await localInstance.post(`/twitter/tweet/${tweetId}/reply`, { text: text, taskId: taskId });
      return response.data;
    }catch(error){
      return error;
    }
  }

  // Repost
  async repostTweet(tweetId, taskId){
    try{
      const response = await localInstance.post(`/twitter/tweet/${tweetId}/repost`, {taskId: taskId});
      return response.data;
    }catch(error){
      return error;
    }
  }

  // Settings

  async updateSettings(config){
    try{
      const response = await localInstance.post(`/settings`, config);
      return response.data;
    }catch(error){
      return error;
    }
  }

  async getSettings(){
    try{
      const response = await localInstance.get(`/settings`);
      return response.data;
    }catch(error){
      return error;
    }
  }

  // Get All Task
  async getAllUsers(page, limit){
    try{
      const response = await localInstance.get(`/user?page=${page}&limit=${limit}`);
      return response.data;
    }catch(error){
      return error;
    }
  }
  async getUserMonitorings(userId, page, limit){
    try{
      const response = await localInstance.get(`/user/${userId}?page=${page}&limit=${limit}`);
      return response.data;
    }catch(error){
      return error;
    }
  }
  async getUserDetails(userId){
    try{
      const response = await localInstance.get(`/user/wallet/${userId}`);
      return response.data;
    }catch(error){
      return error;
    }
  }
  async updateWallet(userId, params){
    try{
      const response = await localInstance.put(`/user/wallet/${userId}`, params);
      return response.data;
    }catch(error){
      return error;
    }
  }
  async walletHistories(userId, page, limit){
    try{
      const response = await localInstance.get(`/user/wallet/history/${userId}?page=${page}&limit=${limit}`);
      return response.data;
    }catch(error){
      return error;
    }
  }
}

export default new Api();