import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allTime: [],
    weekly: [],
    monthly: []
}
export const leaderboardSlice = createSlice({
    name: "leaderboard",
    initialState,
    reducers: {
        setAllTime: (state, action) =>{
            state.allTime = action.payload;
        },
        setWeekly: (state, action) =>{
            state.weekly = action.payload;
        },
        setMonthly: (state, action) =>{
            state.monthly = action.payload;
        },

    }

})

export const { setAllTime, setWeekly, setMonthly } = leaderboardSlice.actions;

export default leaderboardSlice.reducer;