import React from 'react'

const TaskTypeFilter = ({type}) => {
  return (
    <>
    {
        type === 'REFERE' ? 
        <div className="text-white text-left">
            <p className="text-lg text-nowrap md:text-xl">Refer friends</p>
            <p className="text-xs md:text-lg">Each successful referral earns you points.</p>
        </div> 
        : type === 'TWEET' ? 
        <div className="text-white text-left">
            <p className="text-lg text-nowrap md:text-xl">Tweet</p>
            <p className="text-xs md:text-lg">Each successful tweet earns you points.</p>
        </div>
        : type === 'REPOST' ? 
        <div className="text-white text-left">
            <p className="text-lg text-nowrap md:text-xl">Repost</p>
            <p className="text-xs md:text-lg">Each successful repost earns you points.</p>
        </div>
        : type === 'LIKE' ? 
        <div className="text-white text-left">
            <p className="text-lg text-nowrap md:text-xl">Like</p>
            <p className="text-xs md:text-lg">Each successful like earns you points.</p>
        </div>
        : type === 'COMMENT'? 
        <div className="text-white text-left">
            <p className="text-lg text-nowrap md:text-xl">Comment</p>
            <p className="text-xs md:text-lg">Each successful comment earns you points.</p>
        </div> : ''
    }
    </>
    
    
  )
}

export default TaskTypeFilter