import React, { useState } from 'react'
import Section from '../../component/admin/Section'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { isEmpty } from '../../helper/Helper';
import Err from '../../component/Err';
import Api from '../../api/Api';
import { toast } from 'react-toastify';

const TaskAdd = () => {
  const [type, setType] = useState('');
  const [actionType, setActionType] = useState('');
  const [textEnable, setTextEnable] = useState(false);
  const [text, setText] = useState('');
  const [cp, setCp] = useState(0);
  const [tickets, setTickets] = useState(0);
  const [tweetId, setTweetId] = useState('');
  const [tweetLink, setTweetLink] = useState('');
  const [err, setErr] = useState('');

  const handleType = (value) => {
    value === 'TWEET' || value === 'COMMENT' ? setTextEnable(true) : setTextEnable(false);
    setType(value)
  }

  const handleSubmit = async() => {

   
    if(isEmpty(type)) return setErr('Type is required!');
    if(isEmpty(actionType)) return setErr('Action Type is required!');
    if(isEmpty(cp)) return setErr('Community point is required!');
    if(isEmpty(tickets)) return setErr('Tickets are required!');
   
    if(type !== 'TWEET'){
      if(isEmpty(tweetId)) return setErr('Tweet Id is required!');
      if(isEmpty(tweetLink)) return setErr('Tweet Link is required!');
    }
    if(type === 'TWEET' || type === 'COMMENT'){
      if(isEmpty(text)) return setErr('Text is required!');
    }
    
   
    setErr('')
    toast.promise(
        Api.createTask({type, actionType ,cp,tickets,tweetId, tweetLink, text}).then((result)=>{
          console.log('Task Create -- ',result) 
          return result;
        }),
        {
          pending: 'Creating...',
          success: 'Task Created 👌',
          error: 'Task rejected 🤯'
        }
    )
  }

  return (
    <>
    <div className='content'>
        <Section title={'Add Task'} addButton={false}>
        <Err text={err} />
        <Row>
          <Col lg={6}>
            <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label >Task Type:</Form.Label>
                    <Form.Select onChange={(e) => handleType(e.target.value) }>
                      <option value="">Select type</option>
                      <option value="TWEET">Tweet</option>
                      <option value="LIKE">Like</option>
                      <option value="COMMENT">Comment</option>
                      <option value="REPOST">Repost</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label >Action Type:</Form.Label>
                    <Form.Select onChange={(e) => setActionType(e.target.value) }>
                      <option value="">Select Action type</option>
                      <option value="ONETIME">One Time</option>
                      <option value="DAILY">Daily</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Community Point:</Form.Label>
                  <Form.Control type="number" value={cp} onChange={(e)=> setCp(e.currentTarget.value)} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Tickets:</Form.Label>
                  <Form.Control type="number" value={tickets} onChange={(e)=> setTickets(e.currentTarget.value)} />
                </Form.Group>
              </Col>
            </Row>
            { type !== 'TWEET' && 
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Tweet Id:</Form.Label>
                  <Form.Control type="text" value={tweetId} onChange={(e)=> setTweetId(e.currentTarget.value)} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Tweet Link:</Form.Label>
                  <Form.Control type="text" value={tweetLink} onChange={(e)=> setTweetLink(e.currentTarget.value)} />
                </Form.Group>
              </Col>
            </Row>
            }

            {textEnable && 
            <Form.Group className="mb-3">
              <Form.Label>Text:</Form.Label>
              <Form.Control as="textarea" rows={3} value={text} onChange={(e)=> setText(e.currentTarget.value)}/>
            </Form.Group>
            }
           
        
            <Button variant='info' onClick={()=>handleSubmit()}>Submit</Button>
          </Col>
        </Row>
         
        </Section>
    </div>
    </>
  )
}

export default TaskAdd