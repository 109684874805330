import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authSlice'
import leaderboardReducer from './leaderboardSlice'
import menuReducer from './menuSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    leaderboard: leaderboardReducer,
    menu: menuReducer
  },
})